<template>
  <div class="bg-white">
    <div
      class="flex gap-20 pt-24 pb-24 content bg-app flex-wrap justify-center"
    >
      <div class="flex flex-col gap-10 card text-center text-white">
        <h3>5</h3>
        <p>
          {{ $t('statistic.text1') }} <br />
          <span class="light">(2 backend, 2 frontend, 1 QA engineer)</span>
        </p>
      </div>
      <div class="flex flex-col gap-10 card text-center text-white">
        <h3>20+</h3>
        <p>
          {{ $t('statistic.text2') }}
        </p>
      </div>
      <div class="flex flex-col gap-10 card text-center text-white">
        <h3>35%</h3>
        <p>
          {{ $t('statistic.text3') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.content {
  background-image: url('@/assets/statistic.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.card {
  width: 370px;
  h3 {
    font-style: normal;
    font-weight: 900;
    font-size: 70px;
    line-height: 96px;
    font-family: 'avenir-medium';
  }

  p {
    font-family: 'avenir-regular';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    color: #ececec;
  }
}

@media (max-width: 500px) {
  .content {
    background: rgba(165, 250, 255, 0.4);
    border-radius: 40px 40px 0 0;
    padding-top: 40px;
  }

  .card {
    color: #020b20;
    gap: 2px;
    padding: 0 16px;

    p,
    span {
      color: #020b20;
      font-weight: 300;
      font-size: 14px;
      line-height: 19px;
    }
  }
}
</style>
