<template>
  <div class="desktop">
    <button
      class="light locale"
      @click="
        $i18n.locale === 'en' ? ($i18n.locale = 'ru') : ($i18n.locale = 'en')
      "
    >
      {{ $i18n.locale }}
    </button>
    <header class="flex flex-col items-center pb-16">
      <div class="gradient w-full"></div>
      <div class="flex flex-col text-center pb-20 pt-20">
        <p class="company-name regular">NMC</p>
        <p class="subname light">company</p>
      </div>
      <p class="description regular">
        {{ $t('header.description[0]') }} <br />
        {{ $t('header.description[1]') }}
      </p>
      <button class="mt-40 medium">{{ $t('header.btn') }}</button>
    </header>
  </div>

  <div class="mobile">
    <button
      class="light locale"
      @click="
        $i18n.locale === 'en' ? ($i18n.locale = 'ru') : ($i18n.locale = 'en')
      "
    >
      {{ $i18n.locale }}
    </button>
    <header class="flex flex-col items-center pb-16">
      <div class="gradient w-full"></div>
      <div class="flex flex-col text-center pb-4 pt-24">
        <p class="company-name regular">NMC</p>
        <p class="subname light">product</p>
      </div>
      <p class="description regular">
        {{ $t('header.description[0]') }} <br />
        {{ $t('header.description[1]') }}
      </p>
      <button class="mt-8 medium">{{ $t('header.btn') }}</button>
    </header>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.desktop {
  header {
    background: url('@/assets/headerbg.png'), #020b20;
    background-size: cover;
    background-repeat: no-repeat;
    color: #ffffff;
  }

  .locale {
    top: 20px;
    right: 10%;
    position: absolute;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
    color: #ffffff;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 25px;
    padding: 8px;
    text-transform: uppercase;
    text-align: center;
    width: 40px;
    height: 40px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .company-name {
    font-style: normal;
    font-weight: 300;
    font-size: 200px;
    line-height: 180px;
    z-index: 5;
  }
  .subname {
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 55px;
    letter-spacing: 0.6em;
    z-index: 5;
  }
  .description {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
    z-index: 8;
  }

  button {
    background: #ffffff;
    border-radius: 40px;
    color: #020b20;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 27px;
    padding: 26px 72px;
    z-index: 5;
  }

  .gradient {
    position: absolute;
    height: 820px;
    left: 0px;
    top: 0px;
    background: radial-gradient(
      42.7% 43.46% at 50% 50%,
      rgba(165, 250, 255, 0.4) 0%,
      rgba(217, 217, 217, 0) 100%
    );
    border-radius: 512px;
    z-index: 0;
  }
}

.mobile {
  .locale {
    top: 20px;
    left: 5%;
    position: absolute;
    border-radius: 8px;
    background: rgba(165, 250, 255, 0.2);
    color: #020b20;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 25px;
    text-transform: uppercase;
    text-align: center;
    padding: 8px;
    width: 40px;
    height: 40px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  header {
    background: url('@/assets/header-mobile-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    color: #ffffff;
    min-height: 430px;
  }

  .company-name {
    font-style: normal;
    font-weight: 300;
    font-size: 100px;
    line-height: 80px;
    z-index: 5;
  }
  .subname {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.6em;
    z-index: 5;
  }
  .description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    z-index: 8;
  }

  button {
    background: #ffffff;
    border-radius: 40px;
    color: #020b20;
    font-style: normal;
    font-weight: 900;
    font-size: 10px;
    line-height: 14px;
    padding: 12px 40px;
    z-index: 5;
  }
  .gradient {
    position: absolute;
    height: 420px;
    left: 0px;
    top: 0px;
    background: radial-gradient(
      42.7% 43.46% at 50% 50%,
      rgba(165, 250, 255, 0.4) 0%,
      rgba(217, 217, 217, 0) 100%
    );
    border-radius: 512px;
    z-index: 0;
  }
}
</style>
