<template>
  <div class="flex flex-col gap-24 items-center pt-10 pb-24">
    <div class="text-center flex flex-col gap-2">
      <h2 class="h2">
        {{ $t('about.header[0]') }} <br />
        {{ $t('about.header[1]') }}
      </h2>
      <span class="header-sub medium">{{ $t('about.subheader') }} </span>
    </div>
    <div class="flex gap-12 text-center flex-wrap justify-center cards">
      <div class="flex flex-col items-center card">
        <img src="@/assets/clock.svg" alt="clock" />
        <div>
          <h3 class="pt-10 pb-6 medium">
            {{ $t('about.cards.card1.header') }}
          </h3>
          <p class="medium">
            {{ $t('about.cards.card1.text') }}
          </p>
        </div>
      </div>
      <div class="flex flex-col items-center card">
        <img src="@/assets/ok.svg" alt="ok" />
        <div>
          <h3 class="pt-10 pb-6 medium">
            {{ $t('about.cards.card2.header') }}
          </h3>
          <p class="medium">
            {{ $t('about.cards.card2.text') }}
          </p>
        </div>
      </div>
      <div class="flex flex-col items-center card">
        <img src="@/assets/effect.svg" alt="effect" />
        <div>
          <h3 class="pt-10 pb-6 medium">
            {{ $t('about.cards.card3.header') }}
          </h3>
          <p class="medium">
            {{ $t('about.cards.card3.text') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.header-sub {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
}

h3 {
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 41px;
  color: #020b20;
}

p {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  color: #020b20;
}

.card {
  width: 314px;

  img {
    transition: 1s;
  }

  img:hover {
    transform: translate(0, -50px);
    transition: 1s;
  }
}

@media (max-width: 500px) {
  .cards {
    justify-content: start;
  }
  .header-sub {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
  }

  .card {
    flex-direction: row;
    gap: 40px;
    width: 100%;
    padding: 0 16px;

    div {
      text-align: left;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    img {
      width: 92px;
    }

    h3 {
      font-weight: 900;
      font-size: 14px;
      line-height: 19px;
      padding: 0;
    }

    p {
      font-weight: 300;
      font-size: 10px;
      line-height: 14px;
    }
  }

  .card:nth-child(2) {
    flex-direction: row-reverse;
  }
}
</style>
