<template>
  <div class="bg-app flex flex-col items-center gap-16 pb-12 content">
    <div class="text-center flex flex-col items-center gap-5 text-head">
      <h2 class="h2 text-white pt-10">{{ $t('whyWe.header') }}</h2>
      <span class="text-white regular subheader">
        {{ $t('whyWe.subheader') }}
      </span>
    </div>
    <div class="flex flex-col items-start gap-12 text-white cards">
      <div class="flex gap-12 card">
        <img src="@/assets/star.svg" alt="star" />
        <div class="flex flex-col gap-6">
          <h3 class="regular pl-2">{{ $t('whyWe.cards.card1.header') }}</h3>
          <p class="regular pl-2">
            {{ $t('whyWe.cards.card1.text') }}
          </p>
        </div>
      </div>
      <div class="flex gap-12 regular card">
        <img src="@/assets/feedback.svg" alt="messages" />
        <div class="flex flex-col gap-6">
          <h3 class="regular pr-2">{{ $t('whyWe.cards.card2.header') }}</h3>
          <p class="regular pr-2">
            {{ $t('whyWe.cards.card2.text') }}
          </p>
        </div>
      </div>
      <div class="flex gap-12 regular card">
        <img src="@/assets/member.svg" alt="member" />
        <div class="flex flex-col gap-6">
          <h3 class="regular pl-2">{{ $t('whyWe.cards.card3.header') }}</h3>
          <p class="regular pl-2">
            {{ $t('whyWe.cards.card3.text') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
span {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  color: #ececec;
}

h3 {
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
}

p {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  color: #ececec;
}

.subheader {
  width: 50%;
}

.cards {
  align-items: center;
}

.card {
  width: 50%;
}

@media (max-width: 500px) {
  .content {
    border-radius: 40px 40px 0 0;
  }
  span {
    font-weight: 300;
    font-size: 10px;
    line-height: 14px;
  }

  .text-head {
    gap: 4px;
  }

  .cards{
    width: 100%;
  }
  .card {
    flex-direction: row-reverse;
    gap: 12px;
    box-sizing: border-box;
    width: 100%;
    img {
      width: 100px;
    }

    div {
      gap: 4px;
      h3 {
        font-weight: 900;
        font-size: 14px;
        line-height: 19px;
        width: 100%;
      }
      p {
        font-weight: 300;
        font-size: 10px;
        line-height: 14px;
        width: 100%;
      }
    }
  }

  .card:nth-child(2) {
    flex-direction: row;
  }
}

@media (max-width: 1200px) {
  .subheader {
    width: 98%;
  }

  .cards {
    align-items: start;
  }

  .card {
    width: 100%;
  }
}
</style>
