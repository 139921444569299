<template>
  <div class="flex flex-col items-center justify-center main pb-11">
    <h2 class="h2 text-black pt-8 pb-8">{{ $t('feedback.header') }}</h2>
    <transition-group name="slide-fade">
      <div
        class="flex flex-col gap-10 text-center items-center pt-10 pb-10 content"
        v-if="activeTab === 1"
      >
        <img src="@/assets/comment2.jpg" alt="avatar" />
        <p class="medium">
          {{ $t('feedback.feedback1.text') }}
        </p>
        <div class="flex flex-col gap-5 medium">
          <span class="bold">{{ $t('feedback.feedback1.name') }}</span>
          <p>
            {{ $t('feedback.feedback1.company[0]') }} <br />
            {{ $t('feedback.feedback1.company[1]') }}
          </p>
        </div>
      </div>

      <div
        class="flex flex-col gap-10 text-center items-center pt-10 pb-10 content"
        v-else-if="activeTab === 2"
      >
        <img src="@/assets/comment1.jpg" alt="avatar" />
        <p class="medium">
          {{ $t('feedback.feedback2.text') }}
        </p>
        <div class="flex flex-col gap-5 medium">
          <span class="bold">{{ $t('feedback.feedback2.name') }}</span>
          <p>
            {{ $t('feedback.feedback2.company[0]') }} <br />
            {{ $t('feedback.feedback2.company[1]') }}
          </p>
        </div>
      </div>

      <div
        class="flex flex-col gap-10 text-center items-center pt-10 pb-10 content"
        v-else-if="activeTab === 3"
      >
        <img src="@/assets/comment3.jpg" alt="avatar" />
        <p class="medium">
          {{ $t('feedback.feedback3.text') }}
        </p>
        <div class="flex flex-col gap-5 medium">
          <span class="bold">{{ $t('feedback.feedback3.name') }}</span>
          <p>
            {{ $t('feedback.feedback3.company[0]') }} <br />
          </p>
        </div>
      </div>
    </transition-group>
    <div class="flex gap-12 items-center">
      <div
        class="cursor-pointer"
        :class="activeTab === 1 ? 'big-circle' : 'small-circle'"
        @click="activeTab = 1"
      ></div>
      <div
        class="cursor-pointer"
        :class="activeTab === 2 ? 'big-circle' : 'small-circle'"
        @click="activeTab = 2"
      ></div>
      <div
        class="cursor-pointer"
        :class="activeTab === 3 ? 'big-circle' : 'small-circle'"
        @click="activeTab = 3"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
const activeTab = ref(1);

function autoChangeTab() {
  if (activeTab.value === 3) {
    activeTab.value = 1;
  } else {
    activeTab.value += 1;
  }

  setTimeout(() => autoChangeTab(), 10000);
}

autoChangeTab();
</script>

<style lang="scss" scoped>
.content {
  width: 75%;
  p {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 27px;
    color: #000000;

    div {
      span {
        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        line-height: 27px;
        color: #000000;
      }
      p {
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 25px;
        color: #000000;
      }
    }
  }
}
.medium {
  width: 50%;
}

.slide-fade-enter-active {
  transition: all 1.5s ease-out;
}

.slide-fade-leave-active {
  display: none;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(5vw);
  opacity: 0;
}

@media (max-width: 500px) {
  .main {
    background: #ffffff;
  }
  .content {
    border-radius: 40px 40px 0 0;
    background: #ffffff;
    padding: 0 16px 40px 16px;
    width: 80%;

    img {
      width: 100px;
    }

    p {
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
    }

    div {
      p {
        font-weight: 300;
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
  .medium {
    width: 100%;
  }
}

.big-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #020b20;
}

.small-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #020b20;
}
</style>
