<template>
  <div class="main">
    <div class="flex flex-col gap-24 items-center pt-10 pb-16 content">
      <div class="text-center flex flex-col gap-2">
        <h2 class="h2">{{ $t('easyToGet.header') }}</h2>
      </div>
      <div
        class="flex gap-28 text-center flex-wrap justify-center medium cards"
      >
        <div class="flex flex-col items-center card w-full">
          <img src="@/assets/one.svg" alt="one" class="desktop" />
          <img src="@/assets/one-mobile.svg" alt="one" class="mobile" />
          <div class="w-full">
            <h3 class="pt-10 pb-6">{{ $t('easyToGet.cards.card1.header') }}</h3>
            <p>{{ $t('easyToGet.cards.card1.text') }}</p>
          </div>
        </div>
        <div class="flex flex-col items-center card">
          <img src="@/assets/two.svg" alt="two" class="desktop" />
          <img src="@/assets/two-mobile.svg" alt="two" class="mobile" />

          <div>
            <h3 class="pt-10 pb-6">{{ $t('easyToGet.cards.card2.header') }}</h3>
            <p>{{ $t('easyToGet.cards.card2.text') }}</p>
          </div>
        </div>
        <div class="flex flex-col items-center card">
          <img src="@/assets/three.svg" alt="three" class="desktop" />
          <img src="@/assets/three-mobile.svg" alt="three" class="mobile" />

          <div>
            <h3 class="pt-10 pb-6">{{ $t('easyToGet.cards.card3.header') }}</h3>
            <p>{{ $t('easyToGet.cards.card3.text') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.header-sub {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
}

h3 {
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 27px;
  color: #020b20;
}

p {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  color: #020b20;
}

.card {
  width: 330px;

  img{
    transition: 1s;
  }
  
  img:hover {
    transform: translate(0, -50px);
    transition: 1s;
  }
}

@media (max-width: 500px) {
  .main {
    background: #020b20;
  }
  .content {
    border-radius: 40px 40px 0 0;
    margin-top: -20px;
    z-index: 20;
    background: #ffffff;
    gap: 40px;

    .cards {
      justify-content: start;
      padding: 0 16px;
      text-align: left;
    }

    .card {
      flex-direction: row;
      align-items: center;
      gap: 36px;
      width: auto;

      img {
        width: 100px;
      }

      h3 {
        padding-bottom: 8px;
        padding-top: 0;
      }

      p {
        font-weight: 300;
        font-size: 14px;
        line-height: 19px;
      }
    }
  }
}
</style>
