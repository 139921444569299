export default {
  "header": {
    "description": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предоставление услуг по разработке индивидуального программного"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["обеспечения для компаний"])}
    ],
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Записаться на консультацию"])}
  },
  "about": {
    "header": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы разработаем высокотехнологичное решение"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["индивидуально под Ваш продукт"])}
    ],
    "subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это решит многие проблемы и выделит Вас среди конкурентов"])},
    "cards": {
      "card1": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всегда вовремя"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Благодаря проработанной системе Вы сможете в любой момент знать, на каком этапе жизненного цикла находится Ваш продукт."])}
      },
      "card2": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Качественно"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы очень тщательно относимся к формированию лучшей команды, наиболее подходящей под Ваши пожелания."])}
      },
      "card3": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эффективно"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы ценим Ваше время. Именно поэтому разрабатываем проект, который можно будет масштабировать в будущем."])}
      }
    }
  },
  "easyToGet": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получить свое ПО очень просто"])},
    "cards": {
      "card1": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знакомство"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получите бесплатную консультацию и задайте любые вопросы."])}
      },
      "card2": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обсуждение программы"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Решим, какая из наших программ Вам больше всего подходит."])}
      },
      "card3": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получение ПО под ключ"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаете ПО, сделанное индивидуально для Вас."])}
      }
    }
  },
  "statistic": {
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средний состав команды на одном проекте"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительных услуг для Вас. Начиная от reverse - engineering заканчивая поддержкой готовых IT решений."])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На столько увеличивается uptime работы приложения при использовании наших услуг по техническому сопровождению."])}
  },
  "whyWe": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почему стоит выбрать нас?"])},
    "subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы первая компания, которая не только делает программное обеспечение под заказ индивидуально для Вас, но и предлагает ряд преимуществ:"])},
    "cards": {
      "card1": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лучший подбор команды для разработки!"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вам не надо беспокоиться о том, кто сделает работу. Мы предоставим команду высококвалифицированных специалистов с международной сертификацией под Ваш проект."])}
      },
      "card2": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Более 10 лет опыта работы на рынке!"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наша компания уже приняла участие в разработке многих IT решений. Мы продолжаем активно развиваться, углублять свою экспертизу, изучать новые технологии и не боимся брать ответственность за более сложные продукты."])}
      },
      "card3": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжаем быть с вами!"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы предлагаем поддержку как наших успешно завершенных проектов, так и уже существующих IT решений, чтобы обеспечить стабильность и безопасность работы Вашего продукта."])}
      }
    }
  },
  "feedback": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что говорят наши клиенты"])},
    "feedback1": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["«Совместно с NMC сделали два продукта, всем остался доволен. Очень полезно после взять у компании дальнейшую поддержку ПО, рекомендую. Избавили меня от головных болей за работоспособность продукта.»"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иван"])},
      "company": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платформа для автоматизированной закупки электронных компонентов из Китая для импортозамещения. "])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Система отслеживания доставки товаров от закупщика до получателя."])}
      ]
    },
    "feedback2": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["«Задача выполнена на высочайшем уровне! Очень ответственные и приятные в общении. Действительно реализовали продукт индивидуально под нас. Однозначно рекомендую! Обязательно посотрудничаем еще.»"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алик"])},
      "company": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Программное обеспечение для автоматической торговли на криптовалютных биржах (DEX)."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Система мониторинга балансов кошельков клиентов."])}
      ]
    },
    "feedback3": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["«Ребята отличные! Всё быстро выполнено. Андрей всегда был на связи и в одном потоке с нами, а после даже помог презентовать продукт инвесторам! Рекомендую к работе!»"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Екатерина"])},
      "company": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MVP социальной сети для поддержания ментального здоровья, путем выполнения ежедневных заданий."])}
      ]
    }
  },
  "footer": {
    "header": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запишитесь на консультацию для получения"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["коммерческого предложения на разработку"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПО для своей компании прямо сейчас!"])}
    ],
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставить заявку!"])}
  }
}