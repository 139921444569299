<template>
  <div class="bg-app desktop">
    <div
      class="bg-app flex flex-col gap-10 items-center text-center pt-20 pb-4 content"
    >
      <div class="gradient w-8/12"></div>
      <h2 class="h2 text-white medium text">
        {{ $t('footer.header[0]') }} <br />
        {{ $t('footer.header[1]') }}<br />
        {{ $t('footer.header[2]') }}
      </h2>
      <button class="mt-6 medium">{{ $t('footer.btn') }}</button>
      <div class="flex gap-20">
        <a href="http://t.me/nmcprogram" target="_blank"><img src="@/assets/telegram.svg" alt="telegram" /></a>
        <a href="mailto: nmc.product.company@gmail.com"><img src="@/assets/mail.svg" alt="mail" /></a>
        <a href="tel: +77028277312"><img src="@/assets/whatsapp.svg" alt="whatsapp" /></a>
      </div>
    </div>
    <div class="flex justify-center">
      <a href="/"><img src="@/assets/logo.svg" alt="logo" /></a>
    </div>
  </div>

  <div class="mobile pt-12">
    <div class="flex flex-col items-center text-center pt-20 pb-4 content">
      <div class="gradient w-8/12"></div>
      <h2 class="h2 text-white light text">
        {{ $t('footer.header[0]') }}
        {{ $t('footer.header[1]') }}
        {{ $t('footer.header[2]') }}
      </h2>
      <button class="mt-4 medium">{{ $t('footer.btn') }}</button>
      <div class="flex gap-20 mt-8">
        <a href="http://t.me/nmcprogram" target="_blank"
          ><img src="@/assets/telegram.svg" alt="telegram" width="25"
        /></a>
        <a href="mailto: nmc.product.company@gmail.com"><img src="@/assets/mail.svg" alt="mail" width="25" /></a>
        <a href="tel: +77028277312"
          ><img src="@/assets/whatsapp.svg" alt="whatsapp" width="25"
        /></a>
      </div>
    </div>
    <div class="flex justify-center">
      <a href="/"><img src="@/assets/logo.svg" alt="logo" width="144" /></a>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.desktop {
  .content {
    position: relative;
  }
  .gradient {
    position: absolute;
    height: 410px;
    top: 0;
    background: radial-gradient(
      42.7% 43.46% at 50% 50%,
      rgba(165, 250, 255, 0.4) 0%,
      rgba(217, 217, 217, 0) 100%
    );
    border-radius: 512px;
  }
  .text {
    z-index: 5;
  }

  button {
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 27px;
    color: #020b20;
    background: #ffffff;
    padding: 26px 132px;
    border-radius: 40px;
    z-index: 5;
  }
}

.mobile {
  background: url('@/assets/footer-mobile-bg.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 50%;
  .content {
    position: relative;
  }

  .h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 19px;
    padding: 0 16px;
  }
  .gradient {
    position: absolute;
    height: 410px;
    widows: 100%;
    top: 0;
    background: radial-gradient(
      70.94% 50% at 50% 50%,
      rgba(165, 250, 255, 0.2) 0%,
      rgba(165, 250, 255, 0) 72.92%
    );
    border-radius: 512px;
  }
  .text {
    z-index: 5;
  }

  button {
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 19px;
    color: #020b20;
    background: #ffffff;
    padding: 10px 41px;
    border-radius: 40px;
    z-index: 5;
  }
}
</style>
