export default {
  "header": {
    "description": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provision of services for the development of individual software"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["software for companies"])}
    ],
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book a consultation"])}
  },
  "about": {
    "header": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will develop a high-tech solution"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tailor-made for your product"])}
    ],
    "subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will solve many problems and make you stand out from the competition"])},
    "cards": {
      "card1": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Always on time"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks to a well-developed system, you will be able to know at any time at what stage of the life cycle your product is."])}
      },
      "card2": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are very careful about forming the best team that best suits your wishes."])}
      },
      "card3": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effective"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We value your time. That is why we are developing a project that can be scaled in the future."])}
      }
    }
  },
  "easyToGet": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getting your software is very easy"])},
    "cards": {
      "card1": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduction"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get a free consultation and ask any questions."])}
      },
      "card2": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program discussion"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will decide which of our programs suits you best."])}
      },
      "card3": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtaining turnkey software"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You're getting software made specifically for you."])}
      }
    }
  },
  "statistic": {
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average team composition on one project"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional services for you. From reverse-engineering to support of ready-made IT solutions."])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is how much the uptime of the application increases when using our technical support services."])}
  },
  "whyWe": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why choose us?"])},
    "subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are the first company that not only makes custom software for you, but also offers a number of benefits:"])},
    "cards": {
      "card1": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The best selection of development team!"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have to worry about who will do the work. We will provide a team of highly qualified specialists with international certification for your project."])}
      },
      "card2": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than 10 years of market experience!"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our company has already taken part in the development of many IT solutions. We continue to actively develop, deepen our expertise, study new technologies and are not afraid to take responsibility for more complex products."])}
      },
      "card3": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We continue to be with you!"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer support for both our successfully completed projects and existing IT solutions to ensure the stability and security of your product."])}
      }
    }
  },
  "feedback": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What our clients say"])},
    "feedback1": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Together with NMC we made two products, I was satisfied with everything. It's very useful after taking further software support from the company, I recommend it. Saved me from headaches for product performance."])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ivan"])},
      "company": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform for automated procurement of electronic components from China for import substitution. "])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A system for tracking the delivery of goods from the buyer to the recipient."])}
      ]
    },
    "feedback2": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The task was completed at the highest level! Very responsible and pleasant in communication. Really implemented the product individually for us. Definitely recommend! We will definitely cooperate again."])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alik"])},
      "company": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software for automatic trading on cryptocurrency exchanges (DEX)."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System for monitoring the balances of clients' wallets."])}
      ]
    },
    "feedback3": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The guys are great! Everything was done quickly. Andrey was always in touch and in the same stream with us, and after that he even helped to present the product to investors! I recommend it!"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catherine"])},
      "company": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The MVP of a social network for maintaining mental health by completing daily tasks."])}
      ]
    }
  },
  "footer": {
    "header": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up for a consultation to receive"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a commercial offer for development"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software for your company right now!"])}
    ],
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit request!"])}
  }
}